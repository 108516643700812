/* Header */
.Header {
  margin-top: 3rem;
  color: white;
  width: 100%;
  align-items: center;
}

/* Title */
.Title {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 3.5rem;
}

/* Sign In Button */
.Sign-In-Btn {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.3rem;
  color: white;
  background: grey;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}

.Sign-In-Btn:hover {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

/* Text */
.Home-Text {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.3rem;
  margin-top: 2rem;
}

.Text {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.3rem;
  margin-top: 3rem;
  margin-left: 28%;
  margin-right: 28%;
}

/* Sign Out Button */
.Sign-Out-Btn {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.3rem;
  color: white;
  background: grey;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}

.Sign-Out-Btn:hover {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

/* ChatRoom */
.ChatRoom {
  font-family: Georgia, "Times New Roman", Times, serif;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.container {
  width: 100%;
  max-width: 600px;
  padding: 30px 30px;
  background: white;
  border-radius: 50px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.container .content .header {
  display: flex;
  align-items: center;
}
.container .content .header .img img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}
.container .content .header .right {
  margin-left: 10px;
}
.container .content .header .right .name {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: black;
  font-weight: 800;
  font-size: 1em;
}
.container .content .header .right .status {
  font-size: 1em;
  color: #363062;
  font-weight: 500;
  text-align: justify;
}
.container .content .main {
  margin: 30px 0 0;
}
.container .content .main .main_content .messages {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container .content .main .main_content .messages .bot-message {
  width: 100%;
  padding: 15px;
  text-align: center;
  background: orange;
  font-style: 1.1em;
  border-radius: 10px;
  margin: 0 10px 0;
}
.container .content .main .main_content .messages .human-message {
  width: 100%;
  padding: 15px;
  text-align: center;
  background: grey;
  color: white;
  font-style: 1.1em;
  border-radius: 10px;
  margin: 0 10px 0;
  position: relative;
  top: 120px;
  margin-bottom: 20px;
}
.container .content .bottom {
  margin: 120px 0 0;
}
.container .content .bottom .btm {
  display: flex;
  align-items: center;
}
.container .content .bottom .btm .input input {
  width: 420px;
  height: 58px;
  padding-left: 36px;
  outline: none;
  border: none;
  background: rgba(188, 184, 184, 0.498);
  box-sizing: border-box;
  border-radius: 5px;
}
.container .content .bottom .btm .btn {
  margin-left: 10px;
}
.container .content .bottom .btm .btn button {
  font-family: Georgia, "Times New Roman", Times, serif;
  width: 100px;
  height: 50px;
  border: none;
  outline: none;
  border-radius: 5px;
  background: grey;
  color: white;
  font-weight: 500;
  cursor: pointer;
  font-size: 1.1em;
}

.container .content .bottom .btm .btn button:hover {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

.container .content .bottom .btm .btn button i {
  margin-left: 5px;
}

/* Join Community Button */
.Join-Community-Btn {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.3rem;
  color: white;
  background: grey;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}

.Join-Community-Btn:hover {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

/* Extra Space For Scrollbar */
.Extra-Space {
  margin-bottom: 12%;
}

/* Footer component */
.Footer {
  font-family: Georgia, "Times New Roman", Times, serif;
  background-color: grey;
  color: white;
  font-size: 1rem;
  height: 70px;
  margin-top: auto;
  text-align: center;
  justify-content: center;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.Footer a {
  color: white;
  text-decoration: none;
}

.Footer a:hover {
  cursor: pointer;
  color: black;
  text-decoration: none;
}

/* for mobile */
@media (max-width: 600px) {
  /* ChatRoom */
  .container .content .bottom .btm .input input {
    width: 310px;
  }

  /* Extra Space For Scrollbar */
  .Extra-Space {
    margin-bottom: 18%;
  }
}
